/* You can add global styles to this file, and also import other style files */
html {
  overflow-x: hidden;
}
html,body {
 font-size: 14px;
 padding: 0;
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
 color: #5d5d5d;
 line-height: 24px;
 width: 100vw;
}
a {
 text-decoration: none;
 cursor: pointer;
}
a:visited {
  color: blue;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4,h5,h6 {
  color: #333;
}
img {
  width: 100%;
}

.overflow {
  overflow: hidden;
  height: 100vh;
}
.text-indent {
  text-indent:2em;
}

.banner {
  width: 100%;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.red-span {
  font-size: 1.4em;
  background: #222;
  padding: 5px 15px;
  font-weight: 600;
  display: inline-block;
}
.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.container > div:last-child,
.container > p:last-child {
  margin-bottom: 0;
}
.ul-list {
  padding-inline-start: 30px;
  list-style-type: disc;
  color: #333;
  margin: 1em 0;
}
.ul-list li{
  margin: 5px 0;
  font-size: 18px;
}
.ul-list li span{
  color: #5d5d5d;
  font-size: 14px;
}
li > .ul-list {
  margin: 5px 0;
  padding-inline-start: 20px;
}
.text-down {
  vertical-align: sub;
  font-size: 10px !important;
}
.text-up {
  vertical-align: top;
  font-size: 10px !important;
}

.nav {
  height: 52px;
  border-bottom: 2px solid #c7c7c700;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -1px;
  background: rgba(0,0,0,0.4);
  width: 100%;
}
.nav-item {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  height: 54px;
  border-bottom: 2px solid #c7c7c700;
  box-sizing: border-box;
}
.nav-highlight {
  border-bottom: 2px solid #FFF;
}
